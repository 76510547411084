import { CREATE_QUESTION_SET_SUCCESS, DELETE_QUESTION_SET_SUCCESS, GET_QUESTION_SETS_SUCCESS, GET_QUESTION_SET_SUCCESS, GET_QUESTION_SETS_LIST_SUCCESS } from "../actions/types";

const initialState = {
    question_sets: null,
    question_set: null,
    question_sets_list: [],
};

const questions = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_QUESTION_SETS_SUCCESS:
            return {
                ...state,
                question_sets: payload,
            };
        case GET_QUESTION_SETS_LIST_SUCCESS:
            return {
                ...state,
                question_sets_list: payload,
            };
        case CREATE_QUESTION_SET_SUCCESS:
            return {
                ...state,
                question_set: payload,
            };
        case GET_QUESTION_SET_SUCCESS:
            return {
                ...state,
                question_set: payload,
            };
        case DELETE_QUESTION_SET_SUCCESS:
            return {
                ...state,
                question_sets: state.question_sets.filter(item => item.qs_id != payload),
            };
        default:
            return state;
    }
};

export default questions;