import { useState, useEffect } from "react";
import {
  Stack,
  Card,
  CardContent,
  Typography,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getJobs } from "../../redux/actions/jobs";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import moment from "moment";
import { formatDate } from "../../utils/dateUtils";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { styled, alpha } from "@mui/system";
import InputBase from "@mui/material/InputBase";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const Jobs = ({ getJobs, jobs }) => {
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 50,
  });
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(1);
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const [searchKey, setSearchKey] = useState(null);

  const clearSearch = () => {
    navigate(0);
  };
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

  const columns = [
    {
      field: "jb_name",
      headerName: "Job Title",
      flex: 1,
      minWidth: 330,
      renderCell: (params) => (
        <Link to={`/hr/jd-detail/${params.row.jb_id}`}>
          {params.row.jb_name}
        </Link>
      ),
    },
    { field: "jb_min_exp", headerName: "Min Exp", flex: 1 },
    { field: "jb_max_exp", headerName: "Max Exp", flex: 1 },
    // {
    //   field: "batches",
    //   headerName: "Batches",
    //   width: 100,
    //   renderCell: (params) => (
    //     <Link to={`/hr/batches?jobId=${params.row.jb_id}`}>
    //       {params.row.batches}
    //     </Link>
    //   ),
    // },
    {
      field: "applicants",
      headerName: "CVs",
      flex: 1,
      // renderCell: (params) => (
      //   <Link to={`/hr/candidates?jobId=${params.row.jb_id}`}>
      //     {params.row.applicants}
      //   </Link>
      // ),
    },
    { field: "domain", headerName: "Domain", flex: 1 },
    { field: "technology", headerName: "Technology", flex: 1 },
    {
      field: "jb_created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.jb_created_at
            ? formatDate(
                JSON.parse(localStorage.getItem("user")),
                params.row.jb_created_at
              )
            : ""}
        </>
      ),
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
    },
  ];

  useEffect(() => {
    getJobs(paginationModals.page, paginationModals.pageSize);
  }, [paginationModals]);

  useEffect(() => {
    if (searchKey?.length >= 3) {
      setTimeout(() => {
        let search = `&search=${searchKey}`;
        getJobs(paginationModals.page, paginationModals.pageSize, search);
      }, 2000);
    }
    
  }, [paginationModals, refresh, searchKey]);

  const handleRefresh = () => {
    setSearchKey(null);
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    updateTableHeight(jobs?.rows?.length);
  }, [paginationModals.pageSize, jobs]);
  return (
    <>
      <Card
        raised
        sx={{
          minWidth: "100%",
        }}
      >
        <CardContent>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5"> Job Descriptions</Typography>
            {/* <Typography
              sx={{ fontSize: 12 }}
              gutterBottom
              textAlign="right"
              variant="h4"
            ></Typography> */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchKey ? searchKey : ""}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </Search>

              {searchKey && (
                <IconButton onClick={clearSearch}>
                  <CloseIcon />
                </IconButton>
              )}
              <Typography variant="h5">
                <Button
                  component={Link}
                  to="create-jd"
                  variant="text"
                  color="primary"
                  size={"small"}
                >
                  <AddIcon /> Create
                </Button>
                <Button
                  onClick={handleRefresh}
                  variant="text"
                  color="primary"
                  size={"small"}
                >
                  <RefreshIcon /> Refresh
                </Button>
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <div style={{ height: tableHeight, width: "100%" }}>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={
                jobs && jobs.rows && jobs.rows.length > 0 ? jobs.rows : []
              }
              total={jobs && jobs.count ? jobs.count : 0}
              columns={columns}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  jobs: state.jobs.jobList,
});

const mapDispatchToProps = { getJobs };

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);
