import { useState, useEffect } from "react";
import { Stack, Card, CardContent, Typography, Divider, IconButton } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getInterviews } from "../../redux/actions/jobs";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { styled, alpha } from "@mui/system";
import InputBase from "@mui/material/InputBase";



const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const Interviews = ({ getInterviews, interviewList }) => {
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 50,
  });

  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;

  const [searchKey, setSearchKey] = useState(null);

  const clearSearch = () => {
    navigate(0);
  };
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };
  const { t, i18n } = useTranslation();

  const columns = [
    {
      field: "jb_name",
      headerName: "Job Title",
      flex: 1,
      minWidth: 330,
      renderCell: (params) => (
        <Link to={`/hr/jd-detail/${params.row.jb_id}`}>
          {params.row.jb_name}
        </Link>
      ),
    },
    { field: "batches", headerName: "Batches", flex: 1, },
    { field: "applicantsInvited", headerName: "Invite Sent", flex: 1, },
    { field: "applicantsCompleted", headerName: "Completed", flex: 1, },
    {
      field: "createdDate",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.createdDate
            ? formatDate(JSON.parse(localStorage.getItem("user")), params.row.createdDate)
            : ""}
        </>
      ),
    },
  ];

  useEffect(() => {
    getInterviews(paginationModals.page, paginationModals.pageSize);
  }, [paginationModals, getInterviews]);


  useEffect(() => {
    if (searchKey?.length >= 3) {
      setTimeout(() => {
        let search = `&search=${searchKey}`;
        getInterviews(paginationModals.page, paginationModals.pageSize, search);
      }, 2000);
    }
    
  }, [paginationModals, searchKey]);

  useEffect(() => {
    updateTableHeight(interviewList?.rows?.length);
  }, [paginationModals.pageSize, interviewList]);
  return (
    <>
      <Card
        raised
        sx={{
          minWidth: "100%",
        }}
      >
        <CardContent>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">{t('Interviews')}</Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchKey ? searchKey : ""}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </Search>

              {searchKey && (
                <IconButton onClick={clearSearch}>
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
            {/* <Typography
              sx={{ fontSize: 12 }}
              gutterBottom
              textAlign="right"
              variant="h4"
            ></Typography> */}
          </Stack>
          <Divider />
          <div style={{ height: tableHeight, width: '100%' }}>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={
                interviewList &&
                  interviewList.rows &&
                  interviewList.rows.length > 0
                  ? interviewList.rows
                  : []
              }
              total={
                interviewList && interviewList.count ? interviewList.count : 0
              }
              columns={columns}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  interviewList: state.jobs.interviewList,
});

const mapDispatchToProps = { getInterviews };

export default connect(mapStateToProps, mapDispatchToProps)(Interviews);
