import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Stack,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteQuestionSetAction,
  getQuestionSets,
} from "../../redux/actions/questions";
import { formatDate } from "../../utils/dateUtils";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { styled, alpha } from "@mui/system";
import InputBase from "@mui/material/InputBase";
import { use } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const QuestionSet = ({
  question_sets,
  getQuestionSets,
  deleteQuestionSetAction,
}) => {
  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 10,
  });
  const [tableHeight, setTableHeight] = useState(400);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [questionSetToDelete, setQuestionSetToDelete] = useState();
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // useEffect(() => {
  //     getQuestionSets()
  // }, [])

  const [searchKey, setSearchKey] = useState("");
  

  const clearSearch = () => {
   navigate(0);
  };
  useEffect(() => {
    getQuestionSets(paginationModals.page, paginationModals.pageSize);
  }, [paginationModals]);
  
  useEffect(() => {
    if (searchKey?.length >= 3) {
      setTimeout(() => {
        let search = `&search=${searchKey}`;
        getQuestionSets(paginationModals.page, paginationModals.pageSize, search);
      }, 2000);
    }    
  }, [paginationModals,  searchKey]);

  const handleCloseDeleteDialog = () => {
    // setSkillToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleDeleteQuestionSet = async () => {
    deleteQuestionSetAction(questionSetToDelete.qs_id);
    handleCloseDeleteDialog();
  };

  const handleOpenDeleteDialog = (qs) => {
    setQuestionSetToDelete(qs);
    // setIsDeletingMustHave(isMustHave);
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    updateTableHeight(question_sets?.rows?.length);
  }, [paginationModals.pageSize, question_sets]);
  const columns = [
    {
      field: "qs_name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => (
        <Link to={params.row.qs_id}>{params.row.qs_name}</Link>
      ),
    },
    {
      field: "total_questions",
      headerName: "Total Question",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "qs_created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.qs_created_at
            ? formatDate(
                JSON.parse(localStorage.getItem("user")),
                params.row.qs_created_at
              )
            : ""}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Button
          sx={{ color: "red" }}
          onClick={() => handleOpenDeleteDialog(params.row)}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">{t("Question Set")}</Typography>

            <Stack direction="row" spacing={2}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </Search>
              {searchKey && (
                <IconButton onClick={clearSearch}>
                  <CloseIcon />
                </IconButton>
              )}
              <Button
                component={Link}
                to="create"
                variant="text"
                size="small"
                sx={{ color: "green" }}
              >
                <AddIcon /> Add
              </Button>
            </Stack>
          </Box>
          <Divider />
          <div style={{ height: tableHeight, width: "100%" }}>
            {question_sets && (
              <ServerPaginationGridNoRowCount
                setPaginationModals={setPaginationModals}
                paginationModals={paginationModals}
                rowsData={question_sets?.rows}
                getRowId={(row) => row?.qs_id}
                total={question_sets?.count || 0}
                columns={columns}
              />
            )}
          </div>
        </CardContent>
      </Card>
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Question Set?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteQuestionSet} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  question_sets: state.questions.question_sets,
});

const mapDispatchToProps = { getQuestionSets, deleteQuestionSetAction };

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSet);
