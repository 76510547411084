import { useState, useEffect } from "react";
import {
  Stack,
  Card,
  CardContent,
  Typography,
  Divider,
  IconButton,
  Autocomplete,
  Grid,
  TextField,
  Button,
  Box,
  FormControl,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { styled, alpha } from "@mui/system";
import React from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getBatches, deleteBatch } from "../../redux/actions/batches";
import ServerPaginationGridNoRowCount from "../../components/Table/DataGrid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AlertDialog from "../../components/Dialog";
import { formatDate } from "../../utils/dateUtils";
import { getJobsList } from "../../redux/actions/candidate";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const Batches = ({
  getBatches,
  batchesList,
  deleteBatch,
  getJobsList,
  jobsList,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [btId, setBtId] = useState(null);
  const [selectedJob, setSelectedJob] = useState("All");
  const [searchKey, setSearchKey] = useState(null);
  const [filters, setFilters] = useState(null);

  const [paginationModals, setPaginationModals] = useState({
    page: 0,
    pageSize: 50,
  });

  const [tableHeight, setTableHeight] = useState(400);
  const rowHeight = 52;
  const updateTableHeight = (pageSize) => {
    const headerHeight = 56; // Approximate header height (adjust based on your actual header height)
    const footerHeight = 56; // Approximate footer height (adjust based on your actual footer height)
    const newHeight = headerHeight + footerHeight + rowHeight * pageSize;
    setTableHeight(newHeight);
  };

  const columns = [
    {
      field: "bt_name",
      headerName: "Batch Name",
      flex: 1,
      minWidth: 330,
      renderCell: (params) => (
        <Link to={`/hr/batches/batch-detail/${params.row.bt_id}`}>
          {params.row.bt_name}
        </Link>
      ),
    },

    {
      field: "job_name",
      headerName: "Job Description",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Link to={`/hr/jd-detail/${params.row.job_id}`}>
          {params.row.job_name}
        </Link>
      ),
    },
    {
      field: "applicants",
      headerName: "CVs",
      flex: 1,
      // renderCell: (params) => (
      //   <>
      //     {params.row.applicants > 0 ? (
      //       <Link to={`/hr/candidates?batchId=${params.row.bt_id}`}>
      //         {params.row.applicants}
      //       </Link>
      //     ) : (
      //       params.row.applicants
      //     )}
      //   </>
      // ),
    },
    {
      field: "applicantsInvited",
      headerName: "Invite Sent",
      flex: 1,
    },
    {
      field: "applicantsCompleted",
      headerName: "Completed",
      flex: 1,
    },
    {
      field: "applicantsInProgress",
      headerName: "In Progress",
      flex: 1,
    },
    {
      field: "applicantsInPartial",
      headerName: "Partial",
      flex: 1,
    },
    {
      field: "bt_model_name",
      headerName: "Analysis Done By",
      flex: 1,
    },
    {
      field: "bt_created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => (
        <>
          {params && params.row && params.row.bt_created_at
            ? formatDate(
                JSON.parse(localStorage.getItem("user")),
                params.row.bt_created_at
              )
            : ""}
        </>
      ),
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <>
          {params.row.applicants > 0 ? (
            ""
          ) : (
            <IconButton
              onClick={() => submitData(params.row.bt_id, params.row.bt_name)}
            >
              <DeleteForeverIcon sx={{ color: "red" }} />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const clearFilters = () => {
    navigate(0);
  };

  const clearSearch = () => {
    navigate(0);
  };

  useEffect(() => {
    getJobsList();
  }, []);

  const filetrData = async () => {
    ///minE=2&maxE=6&minR=50&maxR=63&minAS=3&maxAS=7&stage=in progres

    // console.log("selectedJob", selectedJob);

    let selectedJobId = jobsList.find((job) => job.value === selectedJob).id
      ? jobsList.find((job) => job.value === selectedJob).id
      : null;
    setSearchKey(null);
    let filterss = `&job=${selectedJobId}`;
    setFilters(filterss);
  };

  const submitData = async (id, name) => {
    setBtId(id);
    setTitle("Delete Batch");
    setContent(`Are you sure you want to delete this batch :  ${name}?`);
    setOpen(true);
  };

  useEffect(() => {
    if (confirm && btId) {
      deleteBatch(btId, null, paginationModals.page, paginationModals.pageSize);
      setConfirm(false);
    }
  }, [confirm]);

  useEffect(() => {
    if (selectedJob === "All") {
      getBatches(
        null,
        paginationModals.page,
        paginationModals.pageSize,
        null,
        null
      );
    } else {
      if (filters) {
        getBatches(
          null,
          paginationModals.page,
          paginationModals.pageSize,
          filters,
          null
        );
      } else if (searchKey) {
        setTimeout(() => {
          let search = `&search=${searchKey}`;
          getBatches(
            null,
            paginationModals.page,
            paginationModals.pageSize,
            null,
            search
          );
        }, 2000);
      } else {
        getBatches(
          null,
          paginationModals.page,
          paginationModals.pageSize,
          null,
          null
        );
      }
    }
  }, [paginationModals, filters, searchKey]);

  useEffect(() => {
    updateTableHeight(batchesList?.rows?.length);
  }, [paginationModals.pageSize, batchesList]);

  const onChangeSelectJob = (e, value, reason) => {
    if (reason === "clear") {
      setSelectedJob("");
    } else {
      setSelectedJob(value.value);
    }
  };

  return (
    <>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title={title}
        setConfirm={setConfirm}
        content={content}
      />
      <Card raised sx={{ minWidth: "100%" }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h5">Filters</Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="text"
                size={"small"}
                color="primary"
                onClick={filetrData}
              >
                Apply
              </Button>
              {filters && (
                <Button
                  variant="text"
                  size={"small"}
                  color="primary"
                  onClick={clearFilters}
                >
                  Clear
                </Button>
              )}
            </Stack>
          </Box>
          <Divider />
          <Grid
            container
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 1,
            }}
          >
            <Grid item xs={12} sm={2.5}>
              <FormControl fullWidth>
                <Autocomplete
                  options={jobsList && jobsList}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Job" />
                  )}
                  // onChange={(e, value) => setSelectedJob(value.value)}
                  onChange={onChangeSelectJob}
                  sx={{ width: 200 }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card
        raised
        sx={{
          minWidth: "100%",
        }}
      >
        <CardContent>
          <Stack
            direction={{ xs: "row", sm: "row" }}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5">Batches</Typography>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  value={searchKey ? searchKey : ""}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </Search>

              {searchKey && (
                <IconButton onClick={clearSearch}>
                  <CloseIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
          <Divider />
          <div style={{ height: tableHeight, width: "100%" }}>
            <ServerPaginationGridNoRowCount
              setPaginationModals={setPaginationModals}
              paginationModals={paginationModals}
              rowsData={batchesList && batchesList.rows ? batchesList.rows : []}
              total={batchesList && batchesList.count ? batchesList.count : 0}
              columns={columns}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  batchesList: state.batches.batchesList,
  jobsList: state.candidate.jobsList,
});

const mapDispatchToProps = { getBatches, deleteBatch, getJobsList };

export default connect(mapStateToProps, mapDispatchToProps)(Batches);
